import { useCallback, useState } from "react";

export interface UseToggle {
  value: boolean;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
}

export function useToggle(value = false): UseToggle {
  const [toggleValue, setToggle] = useState(value);

  const setTrue = useCallback(() => {
    setToggle(true);
  }, []);
  const setFalse = useCallback(() => {
    setToggle(false);
  }, []);
  const toggle = useCallback(() => {
    setToggle((current) => !current);
  }, []);

  return { value: toggleValue, setTrue, setFalse, toggle };
}

export default useToggle;
