import { useContext } from "react";
import { ResponsiveContext } from "../providers";

export function useResponsive() {
  const responsive = useContext(ResponsiveContext);
  if (!responsive)
    throw new Error("You need to wrapp the app in ResponsiveProvider");

  return responsive;
}
