/* eslint-disable @typescript-eslint/ban-types */
import { IconProps } from "@dvs/icons";
import { HTMLAttributes } from "react";

const PlaySVG = (props: IconProps) => (
  <svg
    width="113"
    height="157"
    viewBox="0 0 113 157"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.9"
      d="M1 144.221V13.2897C1 3.40116 12.2892 -2.24343 20.2 3.6897L107.488 69.1556C113.888 73.9556 113.888 83.5556 107.488 88.3556L20.2 153.822C12.2892 159.755 1 154.11 1 144.221Z"
      stroke="url(#paint0_linear_603_306)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_603_306"
        x1="60.6439"
        y1="-10.7103"
        x2="60.6439"
        y2="168.222"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E8BB63" />
        <stop offset="1" stopColor="#D29F3C" />
      </linearGradient>
    </defs>
  </svg>
);

type Props = { isPlaying?: boolean } & HTMLAttributes<HTMLButtonElement>;
export const VideoPlayButton = ({ isPlaying, ...props }: Props) => (
  <button
    className="w-full h-full flex items-center justify-center absolute left-0 right-0 pl-2"
    {...props}
    aria-label="play video"
  >
    <PlaySVG className="sm:w-24 sm:h-24" />
  </button>
);
