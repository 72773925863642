import { ProjectFragment, useShortlistProjectsQuery } from "@dvs/data-access";
import Image from "next/image";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo } from "react";

type Props = { onSelect?: (project: ProjectFragment) => void };
export function Shortlist({ onSelect }: Props) {
  const router = useRouter();
  console.log(router);

  const [projectsResult] = useShortlistProjectsQuery();
  const projects = useMemo(
    () =>
      projectsResult.data?.shortlistProjects?.sort((f, n) =>
        f.name.toLocaleLowerCase().includes("elements")
          ? -1
          : f.name > n.name
          ? 1
          : -1
      ) ?? [],
    [projectsResult.data?.shortlistProjects]
  );

  const imagesForProject = useCallback((project) => {
    return [`/imagine/${project._id}/cover.jpg`];
  }, []);

  useEffect(() => {
    if (projects.length > 0 && router.asPath.includes("#floating-elements")) {
      onSelect?.(
        projects.find((p) => p.name.toLowerCase() === "floating elements")
      );
    }
  }, [onSelect, projects, router.asPath]);

  return (
    <div className="mx-6 sm:max-w-lg sm:mx-auto md:max-w-2xl lg:max-w-3xl xl:max-w-7xl">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-5 gap-6 justify-center">
        {projects.map((p) => (
          <div
            key={p._id}
            className="border border-blue-600 bg-white hover:border-rose-400 transition-all flex flex-col"
          >
            <a
              className="cursor-pointer"
              onClick={() => onSelect(p)}
              data-name={p.name}
              data-uid={p._id}
            >
              <header className="max-w-full">
                <Image
                  src={imagesForProject(p)[0]}
                  key={`${p._id}-cover`}
                  className="block max-w-full"
                  alt={`${p.name} Cover`}
                  width={480}
                  height={320}
                  objectFit="cover"
                />
              </header>
              <div className="p-3 text-center">
                <h4
                  className="text-blue-400 text-center uppercase"
                  aria-label="Project name"
                >
                  {p.name}
                </h4>
                <p className="text-sm text-black/60" aria-label="Participant">
                  {p.participant?.name}
                </p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
