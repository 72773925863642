/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, PropsWithChildren } from "react";

// eslint-disable-next-line no-empty-pattern
export const Button = forwardRef<HTMLButtonElement, any>(
  ({ children }: PropsWithChildren<any>, ref) => {
    return (
      <button ref={ref} className="p-2">
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";
