import { classNames, nl2br } from "@dvs/utils";
import {
  createElement,
  HTMLAttributes,
  PropsWithChildren,
  ReactHTML,
} from "react";
import { useResponsive } from "../hooks/use-responsive";
import { BreakpointsStyle } from "../types/layout";

type Elements = Pick<ReactHTML, "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p">;
type Type = keyof Elements;
const styleForType: Record<Type, BreakpointsStyle> = {
  h1: {
    DEFAULT: { fontSize: "3rem" },
    XS: { fontSize: "2rem", lineHeight: "1" },
    MD: { fontSize: "2.4rem", lineHeight: "1.2" },
  },
  h2: { DEFAULT: { fontSize: "2rem" } },
  h3: { DEFAULT: { fontSize: "1.75rem" }, XS: { fontSize: "1.5rem" } },
  h4: { DEFAULT: { fontSize: "1.5rem" } },
  h5: { DEFAULT: { fontSize: "1.25rem" } },
  h6: {
    DEFAULT: {
      fontSize: "1rem",
      textTransform: "uppercase",
      color: "var(--tw-yellow-500)",
    },
  },
  p: {},
};

type Props = {
  element?: Type;
  as?: Type;
} & Pick<HTMLAttributes<HTMLElement>, "className" | "style">;
export function Heading({
  element,
  as = "p",
  className,
  children,
}: PropsWithChildren<Props>) {
  const { breakpoint } = useResponsive();
  const content = typeof children === "string" ? nl2br(children) : children;
  const elementMarkup = createElement(element ?? "h1", {
    className: classNames(className, "font-serif"),
    style: {
      ...styleForType[as || "h1"][breakpoint],
    },
    dangerouslySetInnerHTML: { __html: content },
  });
  return elementMarkup;
}
