import { classNames } from "@dvs/utils";
import Link from "next/link";
import { CSSProperties } from "react";

const linkClass =
  "font-[Raleway] uppercase text-sm tracking-wider py-2 px-2 hover:text-blue-300";

const linkSecondaryClass =
  "font-[Raleway] uppercase tracking-wider py-1 px-2 text-brown-300 hover:text-blue-300 block w-full";

const footerStyle: CSSProperties = {
  paddingBottom: "env(safe-area-inset-bottom)",
  boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.12)",
};

export const FOOTER_HEIGHT = 36;

type Props = { sticky?: boolean; showTerms?: boolean };
export const Footer = ({ sticky, showTerms }: Props) => {
  return (
    <footer
      className={classNames(
        sticky ? "" : "fixed bottom-0 z-[100] w-screen",
        "bg-yellow-100"
      )}
      style={{ ...footerStyle, ...(showTerms ? { boxShadow: "none" } : {}) }}
    >
      {showTerms && (
        <div className="max-w-5xl mx-auto py-2 md:py-0">
          <ul className="grid md:grid-cols-2 grid-cols-1 text-xs uppercase tracking-wider">
            <li className="flex justify-center first:justify-start last:justify-end text-center md:text-left">
              <Link href={"/termeni-si-conditii"} passHref legacyBehavior>
                <a href="" target={"_blank"} className={linkSecondaryClass}>
                  termenii și condițiile
                </a>
              </Link>
            </li>
            <li className="flex justify-center first:justify-start last:justify-end text-center md:text-left">
              <Link href={"/politica-de-confidentialitate"} passHref legacyBehavior>
                <a href="" target={"_blank"} className={linkSecondaryClass}>
                  politica de confidețialitate
                </a>
              </Link>
            </li>
          </ul>
        </div>
      )}
      <div
        className="max-w-5xl mx-auto"
        style={{ boxShadow: showTerms ? footerStyle.boxShadow : "none" }}
      >
        <ul className="grid grid-cols-2">
          <li className="flex justify-center first:justify-start last:justify-end">
            <a
              href="https://dumbravavlasiei.ro"
              className={linkClass}
              aria-label="Despre Dumbrava"
            >
              Despre Dumbrava
            </a>
          </li>
          <li className="flex justify-center first:justify-start last:justify-end">
            <a href="mailto:concurs@dumbravavlasiei.ro" className={linkClass}>
              Contact
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
