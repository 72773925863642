/* eslint-disable @typescript-eslint/ban-types */
import { IconProps } from "@dvs/icons";
import { HTMLAttributes } from "react";

const PauseSVG = (props: IconProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 25V7C19 6.44772 19.4477 6 20 6H22C22.5523 6 23 6.44772 23 7V25C23 25.5523 22.5523 26 22 26H20C19.4477 26 19 25.5523 19 25Z"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 25V7C9 6.44772 9.44772 6 10 6H12C12.5523 6 13 6.44772 13 7V25C13 25.5523 12.5523 26 12 26H10C9.44772 26 9 25.5523 9 25Z"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

type Props = { isPlaying?: boolean } & HTMLAttributes<HTMLButtonElement>;
export const VideoPauseButton = ({ isPlaying, ...props }: Props) => (
  <button
    {...props}
    className="py-2 px-2 border border-brown-200 rounded-full text-brown-200 hover:border-green-400 transition-all"
    aria-label="pause video"
  >
    <PauseSVG />
  </button>
);
