/* eslint-disable @typescript-eslint/ban-types */
import { useScroll } from "@dvs/scroll";
import { PropsWithChildren, useRef } from "react";
import { useResponsive } from "../../hooks/use-responsive";
import { Footer } from "../footer";
import { Header } from "../header";

const FORM_WIDTH_LARGE = 500;

export const LandingLayout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <div className="sm:pb-9">
      <Header />
      {children}
      <Footer />
    </div>
  );
};
