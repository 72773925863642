export function Logo(props: { className?: string }) {
  return (
    <svg
      width="43"
      height="50"
      viewBox="0 0 43 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.2327 25.1776C22.9329 26.4742 22.785 27.8193 23.6573 29.151C25.186 31.4848 27.5036 32.2687 28.6124 34.872C28.992 35.7633 29.1705 36.7557 29.2249 37.7201C30.9183 35.6793 31.9256 33.2602 32.258 30.6137C32.9632 25.0002 27.367 19.6401 23.3104 16.2537C20.9542 14.2868 18.4379 12.4128 16.6929 9.84063C16.2263 9.15253 15.8331 8.40746 15.5413 7.62724C14.8241 5.70719 14.5014 3.59279 15.2617 1.62992C15.3729 1.34344 15.5313 1 15.5313 1C15.5313 1 17.898 5.84699 25.6461 13.6116C33.3943 21.3759 41.2065 31.2093 27.8034 42.3138C19.9867 48.7899 7.49464 49.2198 0.999983 48.9378M39.4232 25.2835C39.5311 23.1565 40.5505 21.5516 42.4064 20.5419C40.8753 19.4857 39.3559 17.9313 37.3796 17.8562C35.6921 17.7919 34.2533 18.8926 33.4257 20.2886C33.2025 20.6652 32.997 21.0785 32.8515 21.4922C32.7843 21.6838 32.7359 21.885 32.6469 22.0684M33.684 19.7467C33.684 19.7467 32.8032 18.4077 31.3374 16.1624C29.4735 13.307 27.5056 10.2172 26.3609 7.9626C26.3609 7.9626 24.7307 10.1986 25.1546 13.0621M31.3213 34.2485C32.2593 31.0012 30.9957 26.6871 24.2263 21.5727C22.0287 19.9124 19.9058 18.5831 18.1551 16.4101C17.5036 15.6016 17.0136 14.6371 16.871 13.5995C16.7465 12.6948 16.8226 11.4629 17.3166 10.6637M29.3687 37.5432C29.3687 37.5432 31.3172 33.9309 29.0347 30.3781C28.3594 29.3268 27.4734 28.4601 26.5128 27.6726C25.51 26.85 24.4551 26.0968 23.4273 25.3069C22.8217 24.8414 22.2318 24.3497 21.7153 23.7844C20.7321 22.7092 20.1677 21.3157 20.2865 19.8465C20.3208 19.4227 20.351 19.1318 20.4521 18.7184M11.9654 38.4652C12.8039 39.2216 13.7274 39.9259 14.6815 40.5287C18.3477 42.8435 23.0175 43.8775 27.3086 43.1926C31.2286 42.5663 35.0483 40.6701 37.6483 37.607C38.3417 36.7904 38.9953 35.8651 39.5054 34.9209C40.9921 32.1693 41.2045 28.6549 39.7459 25.8564C39.3212 25.0414 38.7845 24.2984 38.2071 23.5868C37.6825 22.9408 37.004 22.104 37.2252 21.2175C37.3268 20.8114 37.6185 20.4348 37.9932 20.2478C38.2966 20.0967 38.7487 20.0813 39.1516 20.4219"
        stroke="url(#paint0_linear_3_5)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3_5"
          x1="21.7032"
          y1="1"
          x2="21.7032"
          y2="49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8BB63" />
          <stop offset="1" stopColor="#D29F3C" />
        </linearGradient>
      </defs>
    </svg>
  );
}
