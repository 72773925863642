/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProjectFragment } from "@dvs/data-access";
import { classNames } from "@dvs/utils";
import { useEffect, useMemo } from "react";
import { Heading } from "../../components/heading";
import useToggle from "../../hooks/use-toggle";

type Props = { project: ProjectFragment; onClose?: () => void };
export function ProjectModal({ project, onClose }: Props) {
  const visible = useToggle(false);
  useEffect(() => {
    if (project) visible.setTrue();
    else visible.setFalse();
  }, [project, visible]);

  const imagesForProject = useMemo(() => {
    if (!project) return [];
    const images = [];
    const imagesNum = project?.images;
    images.push(`/imagine/${project._id}/cover.jpg`);
    for (let i = 1; i <= imagesNum; i++) {
      images.push(`/imagine/${project._id}/${i}.jpeg`);
    }
    return images;
  }, [project]);

  return (
    <>
      {visible.value && (
        <aside className="bg-blue-200 w-screen h-screen fixed z-[200] top-0 overflow-auto">
          {project && (
            <div className="mx-6 max-w-5xl lg:mx-auto">
              <div className="space-y-10 items-center py-10 text-center">
                <button
                  className={classNames(
                    "inline-flex p-3 rounded-full bg-blue-400 transition-all text-white",
                    "hover:bg-blue-300"
                  )}
                  onClick={onClose}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.35355 0.646447C1.15829 0.451185 0.841709 0.451185 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM12.6464 13.3536C12.8417 13.5488 13.1583 13.5488 13.3536 13.3536C13.5488 13.1583 13.5488 12.8417 13.3536 12.6464L12.6464 13.3536ZM0.646447 12.6464C0.451184 12.8417 0.451184 13.1583 0.646447 13.3536C0.841709 13.5488 1.15829 13.5488 1.35355 13.3536L0.646447 12.6464ZM13.3536 1.35355C13.5488 1.15829 13.5488 0.841709 13.3536 0.646447C13.1583 0.451184 12.8417 0.451184 12.6464 0.646447L13.3536 1.35355ZM0.646447 1.35355L12.6464 13.3536L13.3536 12.6464L1.35355 0.646447L0.646447 1.35355ZM1.35355 13.3536L13.3536 1.35355L12.6464 0.646447L0.646447 12.6464L1.35355 13.3536Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
                <header className="bg-blue-200 pt-2 pb-3">
                  <Heading as="h1" className="text-blue-400 uppercase">
                    {project?.name}
                  </Heading>
                  <p className="text-lg">
                    {project?.participant?.name}{" "}
                    {project.participant?.officeName && (
                      <span>({project.participant?.officeName})</span>
                    )}
                  </p>
                  <p className="text-sm text-black/60">
                    {[
                      ...(project?.participant?.members ?? []),
                      ...(project?.participant?.coauthors ?? []),
                      ...(project?.participant?.specialityCollaborators ?? []),
                      ...(project?.participant?.architectureCollaborators ??
                        []),
                    ]
                      .filter((a) => !!a.name)
                      .flatMap((a) => a.name)
                      .filter((value, index, self) => {
                        return self.indexOf(value) === index;
                      })
                      .join(", ")}
                  </p>
                </header>
                <div className="space-y-5 text-center">
                  {imagesForProject.map((i, index) => (
                    <img
                      key={`image-${index}`}
                      src={i}
                      alt={`image-${index}`}
                      decoding="async"
                      loading="lazy"
                    />
                  ))}
                </div>
                <button
                  className={classNames(
                    "inline-flex p-3 rounded-full bg-blue-400 transition-all text-white",
                    "hover:bg-blue-300"
                  )}
                  onClick={onClose}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.35355 0.646447C1.15829 0.451185 0.841709 0.451185 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM12.6464 13.3536C12.8417 13.5488 13.1583 13.5488 13.3536 13.3536C13.5488 13.1583 13.5488 12.8417 13.3536 12.6464L12.6464 13.3536ZM0.646447 12.6464C0.451184 12.8417 0.451184 13.1583 0.646447 13.3536C0.841709 13.5488 1.15829 13.5488 1.35355 13.3536L0.646447 12.6464ZM13.3536 1.35355C13.5488 1.15829 13.5488 0.841709 13.3536 0.646447C13.1583 0.451184 12.8417 0.451184 12.6464 0.646447L13.3536 1.35355ZM0.646447 1.35355L12.6464 13.3536L13.3536 12.6464L1.35355 0.646447L0.646447 1.35355ZM1.35355 13.3536L13.3536 1.35355L12.6464 0.646447L0.646447 12.6464L1.35355 13.3536Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </aside>
      )}
    </>
  );
}
