/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ProjectFragment } from "@dvs/data-access";
import { classNames } from "@dvs/utils";
import Script from "next/script";
import { useRef, useState } from "react";
import { LandingLayout } from "../components/layout/landing";
import { ProjectModal } from "../fragments/modal/project";
import { Shortlist } from "../fragments/shortlist";

export function Index() {
  const [project, setProject] = useState<ProjectFragment>();

  const infoRef = useRef();
  return (
    <>
      <Script src="https://player.vimeo.com/api/player.js"></Script>
      <ProjectModal project={project} onClose={() => setProject(undefined)} />
      <LandingLayout>
        {/* <Model /> */}
        <section className="relative box-border md:max-w-3xl mx-5 md:mx-auto overflow-hidden rounded-xl mt-6">
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              src="https://player.vimeo.com/video/690183761?h=639659e398&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="INTERVIU PREZENTARE CONCURS"
            ></iframe>
          </div>
        </section>
        <section className="relative box-border mt-6 md:max-w-6xl mx-auto p-5">
          <p
            ref={infoRef}
            className={classNames(
              "tracking-widest font-light text-center relative leading-6",
              "sm:text-md leading-tight text-blue-400"
            )}
          >
            {/* <span className="text-base font-[Raleway] font-normal"> */}
            Iată cele mai bune 10 viziuni arhitecturale din concursul Imagine
            Dumbrava Vlăsiei!
            <br />
            50 de echipe au dezvoltat proiecte excepționale pentru definirea
            unui spațiu social al comunității Dumbrava Vlăsiei. Concursul a
            provocat arhitecții să imagineze un centru multifuncțional pentru
            petrecerea timpului liber, întâlniri de socializare sau de lucru,
            care să completeze viziunea Dumbrava Vlăsiei despre reinventarea
            locuirii urbane în România.
            {/* </span> */}
          </p>
        </section>
        <section className="py-20">
          <Shortlist onSelect={setProject} />
        </section>
      </LandingLayout>
    </>
  );
}

export default Index;
